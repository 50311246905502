import { MyLink } from '../MyLink';
import { useEffect, useRef, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

export function HomepageHero({
  videosrc = 'https://res.cloudinary.com/drt9lfnfg/video/upload/v1715853839/static/jroryvnhk2zcpo221e5f.mp4',
}) {
  const [videoVisible, setVideoVisible] = useState(false);
  const { t } = useTranslation(['homepage', 'common']);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      if (videoVisible) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [videoVisible]);

  const handlePlayVideo = () => {
    setVideoVisible(true);
  };

  return (
    <div className="bg-white">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <div className="flex">
              <div className="relative flex items-center gap-x-4 rounded-full bg-indigo-100/50 px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <span className="font-semibold text-indigo-500">{t('common:become_seller')}</span>
                <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                <MyLink prefetch={false} href="/fuer-anbieter" className="flex items-center gap-x-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  {t('homepage:learn_more')}
                  <span className="max-sm:hidden">
                    {t('homepage:and')} {t('common:signup')}
                  </span>
                  <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </MyLink>
              </div>
            </div>
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              {t('homepage:hero_title')}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">{t('homepage:hero_desc')}</p>
            <div className="mt-10 flex items-center gap-x-6">
              <MyLink
                href="/lokale-leistungen?filters=%7B%22location%22%3A%22%22%7D&page=1"
                prefetch={false}
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                {t('homepage:find_studio')}
              </MyLink>
              <MyLink href="/fuer-kunden" prefetch={false} className="text-sm font-semibold leading-6 text-gray-900">
                {t('homepage:learn_more')} <span aria-hidden="true">→</span>
              </MyLink>
            </div>
          </div>

          <div className="relative mt-16 max-md:hidden sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            {/* <svg viewBox="0 0 366 729" role="img" className="relative mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                className="relative z-10"
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                className="relative z-10"
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                className="relative z-0"
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)">
                {!videoVisible ? (
                  <img src="/test/1.png" alt="Esfaras Dashboard Screenshot" className="h-full" />
                ) : (
                  <video
                    className="absolute -right-6 top-6 z-0 h-[684px] w-[316px] rounded-[40px] object-fill"
                    autoPlay
                    width={316}
                    height={684}
                    controls
                    loop>
                    <source src={videosrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </foreignObject>
            </svg> */}

            <div class="relative mx-auto h-[515px] w-[300px] rounded-[2.5rem] border-[14px] border-gray-800 bg-gray-800 shadow-xl dark:border-gray-800">
              <div class="absolute left-1/2 top-0 h-[18px] w-[148px] -translate-x-1/2 rounded-b-[1rem] bg-gray-800"></div>
              <div class="absolute -start-[17px] top-[124px] h-[46px] w-[3px] rounded-s-lg bg-gray-800"></div>
              <div class="absolute -start-[17px] top-[178px] h-[46px] w-[3px] rounded-s-lg bg-gray-800"></div>
              <div class="absolute -end-[17px] top-[142px] h-[64px] w-[3px] rounded-e-lg bg-gray-800"></div>
              <div class="w-[272px] overflow-hidden rounded-[2rem] bg-black dark:bg-gray-800">
                <Image
                  layout="fill"
                  objectFit="cover"
                  objectPosition={'top'}
                  src="/test/1.png"
                  alt="Esfaras Dashboard Screenshot"
                  className={`${videoVisible && '!hidden'} relative z-0 h-[485px] w-full rounded-3xl`}
                />
                <video
                  ref={videoRef}
                  className={`${!videoVisible && 'hidden'} relative z-10 h-[485px] w-[272px] rounded-[30px]`}
                  width={316}
                  height={684}
                  controls
                  loop>
                  <source src={videosrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              {!videoVisible &&
                videosrc ==
                  'https://res.cloudinary.com/drt9lfnfg/video/upload/v1715853839/static/jroryvnhk2zcpo221e5f.mp4' && (
                  <button
                    type="button"
                    onClick={handlePlayVideo}
                    className="absolute left-1/2 top-1/2 flex h-16 w-16 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-black bg-opacity-70">
                    <svg viewBox="0 0 24 24" fill="none" className="h-12 w-12 text-white">
                      <path fill="currentColor" d="M8 5v14l11-7L8 5z" />
                    </svg>
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
