import { MyLink } from '../MyLink';
import { useRef, useState } from 'react';
import Heading from '../Heading';
import {
  Category1,
  Category2,
  Category3,
  Category4,
  Category5,
  Category6,
  Category7,
  Category8,
  Category9,
} from '../Icons';
import { useTranslation } from 'next-i18next';

// Showing Category cards
const StudioServiceCategoryCardLarge = ({ imageUrl, title, description, index, className = 'w-full h-[240px]' }) => {
  const { t } = useTranslation(['homepage', 'common']);

  return (
    <MyLink
      rel="nofollow"
      prefetch={false}
      href={{
        pathname: '/lokale-leistungen',
        query: {
          filters: JSON.stringify({
            services: title,
          }),
          page: 1,
        },
      }}
      className={`${
        index < 3 ? 'h-fit' : 'h-fit'
      } flex min-h-full w-full flex-col rounded-xl border-2 border-solid border-gray-200 bg-white md:w-auto`}>
      <div className="relative">
        {title == 'Aufnehmen' ? (
          <Category1 className={className} />
        ) : title == 'Mischen' ? (
          <Category2 className={className} />
        ) : title == 'Mastern' ? (
          <Category3 className={className} />
        ) : title == 'Musikproduktion' ? (
          <Category4 className={className} />
        ) : title == 'Studio mieten' ? (
          <Category5 className={className} />
        ) : title == 'Podcasts & Hörbücher' ? (
          <Category6 className={className} />
        ) : title == 'Proberaum mieten' ? (
          <Category7 className={className} />
        ) : title == 'Musikunterricht' ? (
          <Category8 className={className} />
        ) : title == 'Sonstige' ? (
          <Category9 className={className} />
        ) : (
          <Category1 className={className} />
        )}

        <div className="flex min-h-[3rem] w-full flex-auto flex-col items-start justify-between rounded-b-xl border-2 border-transparent px-4 py-1.5 text-black md:px-8">
          <h4 className={'text-lg font-semibold' + (title === 'Musikproduktion' ? ' truncate ' : ' ')}>
            {title == 'Aufnehmen'
              ? t('recording')
              : title == 'Mischen'
              ? t('mixing')
              : title == 'Mastern'
              ? t('mastering')
              : title == 'Musikproduktion'
              ? t('musicproduction')
              : title == 'Studio mieten'
              ? t('rent_studio')
              : title == 'Podcasts & Hörbücher'
              ? t('podcasts_and_audiobooks')
              : title == 'Proberaum mieten'
              ? t('rent_rehearsal_room')
              : title == 'Musikunterricht'
              ? t('music_lessons')
              : title == 'Sonstige'
              ? t('other')
              : t('other')}
          </h4>
        </div>
      </div>
    </MyLink>
  );
};

export default function HomepageStudioServiceCategoryGrid({ serviceCategories }) {
  const { t } = useTranslation(['homepage', 'common']);

  return (
    <div className="mx-auto my-20 mb-24 max-w-7xl px-6 lg:px-8">
      <div className="mx-auto flex flex-col sm:max-w-[90vw]">
        <Heading className="mb-1 lg:mb-10" desc={t('studio_services_cat_desc')}>
          {t('local_cats')}
        </Heading>
      </div>
      {/* Desktop Grid */}
      <div className="grid gap-5 px-5 max-lg:grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-12">
        {serviceCategories.map((service, i) => (
          <div key={i} className={i < 3 ? 'md:block lg:col-span-4' : 'md:block lg:col-span-3'}>
            <StudioServiceCategoryCardLarge
              index={i}
              imageUrl={service.image.publicID}
              title={service.name}
              description={service.description}
              queryString={service.queryString}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
