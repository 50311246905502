import React from 'react';

const Heading = ({ children, desc, className = 'mb-10 text-gray-900  ', isCenter = false, ...args }) => {
  return (
    <div className={`relative ${className}`}>
      <div className={isCenter ? 'mx-auto mb-4 w-full max-w-2xl text-center' : 'max-w-2xl'}>
        {children && (
          <h2 className={`text-3xl font-medium md:text-4xl`} {...args}>
            {children}
          </h2>
        )}
        {desc && <h3 className="mt-2 block text-lg font-normal text-gray-700 md:mt-3">{desc}</h3>}
      </div>
    </div>
  );
};

export default Heading;
