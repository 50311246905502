import db from '../lib/dbConnect';
import StudioService from '../models/StudioService';
import { HomepageHero } from '../components/Homepage/HomepageHero';
import { HomepageBanner } from '../components/Homepage/HomepageBanner';
import HomepageStudioServiceCategoryGrid from '../components/Homepage/HomepageStudioServiceCategoryGrid';
import { Faq } from '../components/Homepage/Faq';
import { useMediaQuery, useTheme } from '@mui/material';
import { Footer } from '../components/Footer';
import AdminCreateStudioServiceCategory from '../models/AdminCreateStudioServiceCategory';
import { SixEsfarasFeatures } from '../components/Homepage/SixEsfarasFeatures';
import { CallToAction } from '../components/Homepage/CallToAction';
import SearchEngineHead from '../components/SearchEngineHead';
import globalMeta from '../components/globalmeta';
import LokaleLeistungenCarousel from '../components/Kategorie/LokaleLeistungenCarousel';
import HeaderStatic from '../components/Header/HeaderStatic';
import FooterNavStatic from '../components/FooterNavStatic';
import { MyLink } from '../components/MyLink';
import Heading from '../components/Heading';
import moment from 'moment';
import Image from 'next/image';
import { CategoryGrid } from '../components/DigitalService/CategoryGrid';
import Rating from '../models/Rating';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import OnlineLeistungenCarousel from '../components/Kategorie/OnlineLeistungenCarousel';
import DigitalService from '../models/DigitalService';
import { useEffect } from 'react';
 
function Blog({ posts }) {
  const { t } = useTranslation(['homepage']);

  return (
    <div className="mt-28 bg-white pb-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <Heading desc={t('blog_desc')}>{t('blog_title')}</Heading>
        </div>
        <div className="mx-auto my-12 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post, i) => (
            <article
              key={i}
              className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-white px-8 pb-8 pt-80 sm:pt-48 lg:pt-80">
              <Image
                quality={70}
                layout="fill"
                objectFit={"cover"}
                src={post.image}
                alt={post.title}
                className="absolute inset-0 -z-10 h-full w-full object-cover"
              />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

              <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                <time dateTime={post.date} className="mr-8">
                  {post.date}
                </time>
                <div className="-ml-4 flex items-center gap-x-4">
                  <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <div className="flex gap-x-2.5">
                    <img src={'favicon.ico'} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                    Esfaras
                  </div>
                </div>
              </div>
              <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                <MyLink scroll={false} prefetch={false} href={post.link} target="_blank" rel="noopener">
                  <span className="absolute inset-0" />
                  {post.title.replace(/&amp;/, '&')}
                </MyLink>
              </h3>
            </article>
          ))}
        </div>
        {/* button */}
        <div className="text-center max-sm:mt-2">
            <MyLink
              prefetch={false}
              target="_blank"
              rel="noopener"
              href={'https://blog.esfaras.de/'}
              className="mt-2 rounded-lg bg-indigo-500 px-8 py-2 text-lg text-white">
              {t('to_blog')}
            </MyLink>
          </div>
      </div>
    </div>
  );
}

export default function Home({ carousel1, serviceCategories, carousel2, carousel3, postsJson }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation(['homepage', 'common']);


   return (
    <div className="relative w-full overflow-hidden bg-white">
      <SearchEngineHead
        canonicalUrl={globalMeta.siteUrl}
        title="Esfaras | Finde, vergleiche und buche Tonstudios in deiner Nähe"
        description="Finde das perfekte Tonstudio für einen super Preis überall in Deutschland."
        ogType="website">
        <meta name="google-site-verification" content="EQEiHUnJ4ASaG-3hGJduu5S9l4ZhW6NR8wqB4ETkgMY" />
        <meta name="p:domain_verify" content="8fecd2cea81dbdffef4394c3bc480e97"/>
      </SearchEngineHead>
      <HeaderStatic />
      <main className="relative z-10 pt-24 2k-screens:container">
        <HomepageHero />

        <CallToAction />

        <div className="mb-10 md:mx-10 md:mt-5 xl:mx-20">
          <LokaleLeistungenCarousel title={t('carousel1')} results={carousel1} linkToDetailpage homepage />
        </div>
        <HomepageStudioServiceCategoryGrid serviceCategories={serviceCategories} />
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <CategoryGrid homepage />
        </div>

        <div className="mb-28 md:mx-10 md:mt-5 xl:mx-20">
          <OnlineLeistungenCarousel
            title={'Eine Auswahl aus den Online Kategorien'}
            results={carousel3}
            linkToDetailpage
            homepage
          />
        </div>
        <SixEsfarasFeatures />

        <HomepageBanner />
        <Blog posts={postsJson} />
        <Faq />
      </main>
      <Footer />
      {isMobile ? <FooterNavStatic staticPage /> : null}
    </div>
  );
}

export async function getStaticProps({ locale }) {
  await db.connect();

  const serviceCategories = await AdminCreateStudioServiceCategory.find()
    .select('image name description queryString')
    .lean();
  const sanitizedServiceCategories = serviceCategories
    .map((service) => ({
      index:
        service.queryString === 'recording'
          ? 1
          : service.queryString === 'mixing'
          ? 2
          : service.queryString === 'mastering'
          ? 3
          : service.queryString === 'musicproduction'
          ? 4
          : service.queryString === 'rent-studio'
          ? 5
          : service.queryString === 'podcasts-audiobooks'
          ? 6
          : service.queryString === 'rent-rehearsal-room'
          ? 7
          : service.queryString === 'music-lessons'
          ? 8
          : 9,
      id: service.id,
      image: service.image,
      name: service.name,
      queryString: service.queryString,
      description: service.description,
    }))
    .sort((a, b) => a.index - b.index);
  const serializedServiceCategories = JSON.parse(JSON.stringify(sanitizedServiceCategories));

  const excludeByTitle = [
    'Tonstudio mieten in Berlin',
    'Abmischung von Hip Hop, Rap und Pop',
    'Ich Mische Sound Tracks und Musik bis es perfekt für deinen Geschmack ist!',
  ];
  const premiumLeistungen = await StudioService.find({ listingTitle: { $nin: excludeByTitle } })
    .select('listingTitle description pricing studio images service')
    .sort({ createdAt: -1 }) // Sort by creation date in descending order
    .lean()
    .populate({
      path: 'studio',
      model: 'StudioListing',
      select: 'studioLocation locationFeatures studiotype',
    })
    .populate({
      path: 'profile',
      model: 'OtherProfile',
    })
    .populate({
      path: 'service',
      model: 'AdminStudioServiceCategories',
      select: 'name',
    })
    .then((docs) => {
      const uniqueDocs = docs.filter((doc, index, self) => {
        // Filter out documents that have duplicate studio IDs and match 'Premium Studio'
        return (
          doc?.studio?.studiotype === 'Premium Studio' &&
          index === self.findIndex((d) => d.studio && doc.studio && d.studio._id === doc.studio._id)
        );
      });
      return uniqueDocs;
    })
    .catch((error) => {
      console.error(error);
    });
  const carousel1 = JSON.parse(JSON.stringify(premiumLeistungen)).slice(0, 8);
  for (let service of carousel1) {
    const ratings = await Rating.find({ studioService: service?._id }).lean();
    service.ratings = {
      stars: ratings?.reduce((total, item) => {
        return (
          total +
          (item?.ordererRating?.as_described + item?.ordererRating?.communication + item?.ordererRating?.recommend) / 3
        );
      }, 0),
      count: ratings?.length,
    }; // Attach ratings to each service
  }

  // const recordingLeistungen = await StudioService.find({ listingTitle: { $nin: excludeByTitle } })
  //   .select('listingTitle description pricing studio images service')
  //   .sort({ createdAt: -1 }) // Sort by creation date in descending order
  //   .lean()
  //   .populate({
  //     path: 'studio',
  //     model: 'StudioListing',
  //     select: 'studioLocation locationFeatures studiotype',
  //   })
  //   .populate({
  //     path: 'profile',
  //     model: 'OtherProfile',
  //   })
  //   .populate({
  //     path: 'service',
  //     model: 'AdminStudioServiceCategories',
  //     select: 'name',
  //   })
  //   .then((docs) => {
  //     const uniqueDocs = docs.filter((doc, index, self) => {
  //       // Filter out documents that have duplicate studio IDs and match 'Premium Studio'
  //       return (
  //         doc?.service?.name === 'Aufnehmen' &&
  //         doc?.studio?.studiotype !== 'Premium Studio' &&
  //         index === self.findIndex((d) => d.studio && doc.studio && d.studio._id === doc.studio._id)
  //       );
  //     });
  //     return uniqueDocs;
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });
  // const carousel2 = JSON.parse(JSON.stringify(recordingLeistungen)).slice(0, 8);
  // for (let service of carousel2) {
  //   const ratings = await Rating.find({ studioService: service?._id }).lean();
  //   service.ratings = {
  //     stars: ratings?.reduce((total, item) => {
  //       return (
  //         total +
  //         (item?.ordererRating?.as_described + item?.ordererRating?.communication + item?.ordererRating?.recommend) / 3
  //       );
  //     }, 0),
  //     count: ratings?.length,
  //   }; // Attach ratings to each service
  // }

  const online_leistungen = await DigitalService.find({})
    .sort({ createdAt: -1 })
    .lean()
    .populate({
      path: 'category',
      populate: ['metadata', 'subCategories'],
    })
    .populate({
      path: 'subcategory',
      populate: ['metadata'],
    })
    .populate({
      path: 'user',
      model: 'users',
    })
    .populate({
      path: 'profile',
      model: 'OtherProfile',
    })
    .populate({
      path: 'studio',
      model: 'StudioListing',
    })
    .then((docs) => {
      const uniqueDocs = docs.filter((doc, index, self) => {
        // Filter out documents that have duplicate studio IDs and match 'Premium Studio'
        return index === self.findIndex((d) => d.user._id === doc.user._id);
      });
      return uniqueDocs;
    })
    .catch((error) => {
      console.error(error);
    });

  for (let service of online_leistungen) {
    const ratings = await Rating.find({ digitalService: service?._id }).lean();
    service.ratings = {
      stars: ratings?.reduce((total, item) => {
        return (
          total +
          (item?.ordererRating?.as_described + item?.ordererRating?.communication + item?.ordererRating?.recommend) / 3
        );
      }, 0),
      count: ratings?.length,
    }; // Attach ratings to each service
  }
  const carousel3 = JSON.parse(JSON.stringify(online_leistungen)).slice(0, 8);

  // const excludeStudioByName = [
  //   'Lamsa Studios',
  //   'Forrealstudio',
  //   'MrLivePipe Studios UG',
  //   'Tinush Mix & Mastering',
  //   'Tinush Mix & Mastering Studio',
  // ];
  // const premiumStudios = await StudioListing.find({
  //   studiotype: 'Premium Studio',
  //   studioName: { $nin: excludeStudioByName },
  //   'logo.type': { $ne: 'thumbnail' },
  // })
  //   .select('logo studidotype studioName studioLocation profileText')
  //   .limit(8)
  //   .lean();
  // const carousel3 = JSON.parse(JSON.stringify(premiumStudios));

  // const mostStudiosByCity = await StudioListing.aggregate([
  //   {
  //     $group: {
  //       _id: '$studioLocation.city', // Group by city
  //       numberOfStudios: { $sum: 1 }, // Count the number of studios in each city
  //     },
  //   },
  //   {
  //     $sort: { numberOfStudios: -1 }, // Sort by the count in descending order
  //   },
  //   {
  //     $project: {
  //       city: '$_id', // Include city name in the output
  //       _id: 0, // Exclude the _id field
  //       numberOfStudios: 1, // Include the number of studios
  //     },
  //   },
  // ]);

  // To get an array of cities in descending order by the number of studios:
  // const studioCities = mostStudiosByCity.map((entry) => entry.city);

  // blog posts
  const posts = await fetch('https://blog.esfaras.de/wp-json/wp/v2/posts?_embed&per_page=3');
  const postsJson = await posts.json();
  const postsSezialized = await postsJson.map((post) => ({
    date: moment(post?.date).format('DD MMM, YYYY'),
    description: post?.excerpt?.rendered,
    title: post?.title?.rendered,
    link: post?.link,
    image: post?._embedded?.['wp:featuredmedia']?.[0]?.source_url || '',
  }));

  // data
  // let otherCategories;
  // const fetchedData = await CategorySeoPage.find().lean();
  // otherCategories = JSON.parse(JSON.stringify(fetchedData));

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'homepage'])),
      carousel1: carousel1 || null,
      // carousel2: carousel2 || null,
      carousel3: carousel3 || null,
      serviceCategories: serializedServiceCategories || null,
      // studioCities: studioCities || null,
      postsJson: postsSezialized || null,
      // otherCategories: otherCategories || null,
    },
    revalidate: 1200,
  };
}
