import { Icon24 } from '../Icons';
import { useTranslation } from 'next-i18next';

// for both seller and buyer
export const SixEsfarasFeatures = () => {
  const { t } = useTranslation(['homepage']);
  const arr = [
    <div className="flex max-w-md flex-col px-5">
      <h4 className="mb-3 text-xl font-semibold leading-6">{t('6_feat1_title')}</h4>
      <p className="text-base text-gray-900">{t('6_feat1_desc')} </p>
    </div>,
    <div className="flex max-w-md flex-col px-5">
      <h4 className="mb-3 text-xl font-semibold leading-6">{t('6_feat2_title')}</h4>
      <p className="text-base text-gray-900">{t('6_feat2_desc')}</p>
    </div>,
    <div className="flex max-w-md flex-col px-5">
      <h4 className="mb-3 text-xl font-semibold leading-6">{t('6_feat3_title')}</h4>
      <p className="text-base text-gray-900">{t('6_feat3_desc')}</p>
    </div>,
    <div className="flex max-w-md flex-col px-5">
      <h4 className="mb-3 text-xl font-semibold leading-6">{t('6_feat4_title')}</h4>
      <p className="text-base text-gray-900">{t('6_feat4_desc')}</p>
    </div>,
    <div className="flex max-w-md flex-col px-5">
      <h4 className="mb-3 text-xl font-semibold leading-6">{t('6_feat5_title')}</h4>
      <p className="text-base text-gray-900">{t('6_feat5_desc')}</p>
    </div>,
    <div className="flex max-w-md flex-col px-5">
      <h4 className="mb-3 text-xl font-semibold leading-6">{t('6_feat6_title')}</h4>
      <p className="text-base text-gray-900">{t('6_feat6_desc')}</p>
    </div>,
    <div className="flex max-w-md flex-col px-5">
      <h4 className="mb-3 text-xl font-semibold leading-6">{t('6_feat7_title')}</h4>
      <p className="text-base text-gray-900">{t('6_feat7_desc')}</p>
    </div>,
  ];
  return (
    <div className="mx-auto my-20 max-w-7xl bg-white px-6 lg:my-20 lg:px-8">
      <div className="mx-auto mb-10 max-w-xl px-4 sm:max-w-xl sm:text-center lg:max-w-2xl">
        <h2 className="relative mb-6 max-w-3xl text-3xl font-medium leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="absolute -right-14 -top-7 z-0 -ml-20 -mt-8 w-32 sm:left-10 sm:top-5 md:top-5 lg:left-16 lg:-mt-10 lg:w-32 xl:left-16">
            <Icon24 className="h-16 w-16 fill-[#0f3c69]" />
          </span>
          {t('6_feat_title')}
        </h2>
        <h3 className="text-lg text-gray-700">{t('6_feat_desc')}</h3>
      </div>

      <section className="grid gap-3 px-2 lg:container md:grid-cols-2 md:gap-5 lg:grid-cols-4">
        {arr?.map((group, i) => (
          <div className="mb-2 flex flex-col gap-8 rounded-2xl border bg-white p-3 shadow-md">{group}</div>
        ))}
      </section>

      {/* Slider
      <div className="hidden w-full items-center justify-end gap-3 px-10 pb-6 text-gray-800 md:flex">
        <button
          className="rounded-full border bg-white p-2.5 drop-shadow"
          onClick={() => swiperRef.current.slidePrev()}>
          <TfiAngleLeft className={classNames('h-3 w-3 stroke-1', swiperStartAndEnd.isBeginning && '!text-gray-300')} />
        </button>
        <button
          className="rounded-full border bg-white p-2.5 drop-shadow"
          onClick={() => swiperRef.current.slideNext()}>
          <TfiAngleRight className={classNames('h-3 w-3 stroke-1', swiperStartAndEnd.isEnd && '!text-gray-300')} />
        </button>
      </div>
      <div className="bg-white max-md:pt-3">
        <Swiper
          className="services-slider"
          spaceBetween={25}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
              spaceBetween: 20,
              autoplay: false,
            },
            480: {
              slidesPerView: 1.8,
              spaceBetween: 20,
              autoplay: false,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 30,
              autoplay: false,
            },
            1024: {
              slidesPerView: 2.6,
              spaceBetween: 40,
              autoplay: false,
            },
            1280: {
              slidesPerView: 3.2,
              spaceBetween: 40,
              autoplay: true,
            },
            1536: {
              slidesPerView: 4,
              spaceBetween: 40,
              autoplay: true,
            },
            1920: {
              slidesPerView: 5,
              spaceBetween: 40,
              autoplay: true,
            },
          }}
          slidesOffsetBefore={20}
          slidesOffsetAfter={20}
          onSlideChange={(swiper) => {
            setSwiperStartAndEnd({
              isBeginning: swiper.isBeginning,
              isEnd: swiper.isEnd,
            });
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onReachEnd={(swiper) => {
            setSwiperStartAndEnd({
              isBeginning: swiper.isBeginning,
              isEnd: swiper.isEnd,
            });
          }}>
          {arr?.map((group, i) => (
            <SwiperSlide key={i}>
              <div className="mb-2 flex flex-col gap-8 rounded-2xl border bg-white p-3 shadow-md">{group}</div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}
    </div>
  );
};
