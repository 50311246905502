import { MyLink } from '../MyLink';
import Heading from '../Heading';
import { Category10, Category11, Category12, Category13 } from '../Icons';
import { useTranslation } from 'next-i18next';

const Li = ({ category }) => {
  return (
    <li className="">
      <MyLink prefetch={false} href={`/online-leistungen/${category.name}`}>
        {category.translation}
      </MyLink>
    </li>
  );
};

const Description = ({ menu }) => {
  return (
    <ul className="mt-3 list-none space-y-3 text-base">
      {menu.map((item, index) => {
        return <Li key={index} category={item} />;
      })}
    </ul>
  );
};

const MobileCategoryCard = ({ title, description, className = 'w-full h-full' }) => {
  return (
    <div className={`flex min-h-full w-full max-w-[500px] flex-col rounded-xl bg-white md:w-full lg:w-auto`}>
      <div className="relative w-full max-2xl:h-[230px] max-md:h-[200px] 2xl:h-[270px]">
        {title == 'Musikproduktion & Songwriting' ? (
          <Category10 className={className} />
        ) : title == 'Tontechnik & Postproduktion' ? (
          <Category11 className={className} />
        ) : title == 'Voice Over & Streaming' ? (
          <Category12 className={className} />
        ) : title == 'Unterricht & Transkription' ? (
          <Category13 className={className} />
        ) : (
          <Category13 className={className} />
        )}
      </div>
      <div className="relative flex min-h-[120px] w-full flex-auto flex-col items-start justify-between px-4 py-3 md:px-8 md:py-6">
        <div className={'min-h-[160px] sm:min-h-[150px]  md:min-h-max'}>
          <h1 className="text-xl font-semibold">{title}</h1>
          <div className="mt-2 text-base text-[#747474] md:text-lg">
            <Description menu={description} />
          </div>
        </div>
      </div>
    </div>
  );
};

export function CategoryGrid({ homepage }) {
  const { t } = useTranslation(['homepage', 'common']);
  const menu1 = [
    { name: 'Produzenten & Komponisten', translation: t('producers_and_composers') },
    { name: 'Songwriter', translation: 'Songwriter' },
    { name: 'Beats produzieren', translation: t('beats_production') },
    { name: 'Sänger & Vokalisten', translation: t('singers_and_vocalists') },
    { name: 'Studiomusiker', translation: t('session_musicians') },
    { name: 'Erkennungsmelodien & Intros', translation: t('jingles_and_intros') },
  ];

  const menu2 = [
    { name: 'Mischen & Mastern', translation: t('mixing_and_mastering') },
    { name: 'Audiobearbeitung', translation: t('audio_editing') },
    { name: 'Vocals bearbeiten', translation: t('vocals_editing') },
    { name: 'Audio-Logo & Sound-Branding', translation: t('audio_logo_and_sound_branding') },
  ];

  const menu3 = [
    { name: 'Voice Over', translation: 'Voice Over' },
    { name: 'Podcast produzieren', translation: t('podcast_production') },
    { name: 'Hörbuchproduktion', translation: t('audiobook_production') },
    { name: 'Audio- & Radiobewerbung', translation: t('audio_ads_production') },
    { name: 'Sprachsynthese & KI', translation: t('voice_synthesis_and_ai') },
  ];

  const menu4 = [
    { name: 'Online-Musikunterricht', translation: t('online_music_lessons') },
    { name: 'Musik-Transkription', translation: t('music_transcription') },
    { name: 'Musik- & Audio-Beratung', translation: t('music_and_audio_advice') },
  ];
  // const menu1 = [
  //   t('producers_and_composers'),
  //   'Songwriter',
  //   t('beats_production'),
  //   t('singers_and_vocalists'),
  //   t('session_musicians'),
  //   t('jingles_and_intros'),
  // ];
  // const menu2 = [
  //   t('mixing_and_mastering'),
  //   t('audio_editing'),
  //   t('vocals_editing'),
  //   t('audio_logo_and_sound_branding'),
  // ];
  // const menu3 = [
  //   'Voice Over',
  //   t('podcast_production'),
  //   t('audiobook_production'),
  //   t('audio_ads_production'),
  //   t('voice_synthesis_and_ai'),
  // ];
  // const menu4 = [t('online_music_lessons'), t('music_transcription'), t('music_and_audio_advice')];

  const menu = [menu1, menu2, menu3, menu4];
  return (
    <div className="mt-20 pb-20">
      <div className="mx-auto flex max-w-[90vw] flex-col gap-10">
        <Heading className="mb-1 lg:mb-10" desc={t('online_categories_desc')}>
          {t('online_categories')}
        </Heading>
      </div>
      {/* Desktop Grid */}
      <div className="grid gap-5 px-5 max-lg:grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-12">
        {menu.map((menu, i) => (
          <div key={i} className={i < 3 ? 'md:block lg:col-span-4' : 'md:block lg:col-span-4'}>
            <MobileCategoryCard
              title={
                i === 0
                  ? t('musicproduction_and_songwriting')
                  : i === 1
                  ? t('audio_engineering_and_post_production')
                  : i === 2
                  ? t('voice_over_and_streaming')
                  : t('lessons_and_transcirption')
              }
              description={menu}
              queryString={'service.queryString'}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
