import { MyLink } from '../MyLink';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../utils';
import { Icon2, Icon3 } from '../Icons';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

const type = [
  {
    id: 1,
    name: 'Lokal',
    icon: (props) => {
      <Icon3 {...props} />;
    },
  },
  {
    id: 2,
    name: 'Online',
    icon: (props) => {
      <Icon3 {...props} />;
    },
  },
];
const WordRotator = () => {
  const { t } = useTranslation(['homepage', 'common']);
  const words = [t('song'), t('podcast'), t('beat'), t('audio_ad'), t('soundtrack'), t('audiobook')];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentWordIndex((currentWordIndex) => (currentWordIndex + 1) % words.length);
        setFade(true);
      }, 150);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <span
      className={`inline-block min-w-[13rem] text-start text-indigo-500 transition-opacity duration-150 ${
        fade ? 'opacity-100' : 'opacity-0'
      }`}>
      {words[currentWordIndex]}
    </span>
  );
};

export const CallToAction = () => {
  const [clicked, setClicked] = useState(type[0]);

  const [inputval, setInputval] = useState('');

  const { t } = useTranslation(['homepage', 'common']);
  const router = useRouter();

  const localSearchLink = {
    pathname: '/lokale-leistungen',
    query: {
      filters: JSON.stringify({
        location: inputval,
      }),
      page: 1,
    },
  };
  //
  const onlineSearchLink = {
    pathname: '/online-leistungen',
    query: {
      filters: JSON.stringify({
        search: inputval,
        priceMin: 5,
        priceMax: 9999,
        sortBy: 'Empfohlen',
      }),
      page: 1,
    },
  };

  const theSearchLink = clicked.name == 'Online' ? onlineSearchLink : localSearchLink;

  const handleSubmit = (event) => {
    event.preventDefault();
    router.push(theSearchLink);
  };

  return (
    <section className="relative z-10 mb-24 bg-white max-sm:py-10">
      <div className="container mx-auto flex flex-col items-center justify-center text-center">
        <h2 className="mb-6 text-4xl font-semibold text-black md:text-4xl">
          <span className="text-indigo-500">{t('homepage:get')}</span> {t('homepage:the_perfect')} <WordRotator />
        </h2>
        <h3 className="mb-10 max-w-4xl text-lg text-black 2k-screens:max-w-5xl">{t('call_to_action_desc')}</h3>
        <form
          className="flex justify-center max-sm:px-1"
          onKeyDown={(e) => {
            if (e?.keyCode == 13) {
              handleSubmit(e);
            }
          }}
          tabIndex="0"
          onSubmit={(event) => {
            handleSubmit(event);
          }}>
          <Listbox value={clicked} onChange={setClicked}>
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="relative h-full w-full cursor-default rounded-xl rounded-r-none bg-white pl-3 pr-8 text-left font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:w-[7rem] sm:text-sm sm:leading-6">
                    <span className="flex items-center gap-1 truncate">
                      {clicked.name == 'Online' ? <Icon2 className="h-5 w-5" /> : <Icon3 className="h-5 w-5" />}
                      {clicked.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {type.map((person) => (
                        <Listbox.Option
                          key={person.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-indigo-500 text-white' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={person}>
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {person.name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          <input
            type="text"
            placeholder={
              clicked.name == 'Lokal'
                ? 'Hamburg, Berlin, Frankfurt, Stuttgart, Köln, ...'
                : 'Mixing, Mastering, Voice Over, Vocals, ...'
            }
            value={inputval}
            onChange={(event) => setInputval(event.target.value)}
            className="rounded-none border-l-0 border-gray-300 px-6 py-2 outline-none ring-0 max-sm:w-full sm:w-64 md:w-96"
          />
          <button
            type="submit"
            aria-label="Starte Suche"
            className="rounded-r-lg bg-indigo-500 px-6 py-3 text-white hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 max-sm:px-4">
            <MagnifyingGlassIcon className="h-6 w-6" />
          </button>
        </form>
        <p className="mb-10 text-xs italic text-gray-400">{t('call_to_action_input')}</p>

        <p className="text-lg text-black">{t('call_to_action_cat')}</p>
        <div className="mt-4 flex max-w-4xl flex-wrap justify-center gap-x-4 gap-y-2 2k-screens:max-w-5xl">
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Musikproduktion%22%7D&page=1"
            className="flex font-semibold text-black">
            <Icon3 className="h-6 w-6"></Icon3>
            {t('musicproduction')}
          </MyLink>
          {/* <MyLink
            prefetch={false}       
            rel="nofollow"
            href="/online-leistungen/Beats%20produzieren"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Beats Produktion
          </MyLink> */}
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Mischen%22%7D&page=1"
            className="flex font-semibold text-black">
            <Icon3 className="h-6 w-6"></Icon3>
            {t('audio_mixing')}
          </MyLink>
          {/* <MyLink prefetch={false}             
            rel="nofollow"
            href="/online-leistungen/Voice%20Over" className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Voice Over
          </MyLink> */}
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Aufnehmen%22%7D&page=1"
            className="flex font-semibold text-black">
            <Icon3 className="h-6 w-6"></Icon3>
            {t('recording')}
          </MyLink>
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Mastern%22%7D&page=1"
            className="flex font-semibold text-black">
            <Icon3 className="h-6 w-6"></Icon3>
            {t('audio_mastering')}
          </MyLink>
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Studio+mieten%22%7D&page=1"
            className="flex font-semibold text-black">
            <Icon3 className="h-6 w-6"></Icon3>
            {t('rent_studio')}
          </MyLink>
          {/* <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/Podcast%20produzieren"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Podcast Produktion
          </MyLink> */}
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Proberaum+mieten%22%7D&page=1"
            className="flex font-semibold text-black">
            <Icon3 className="h-6 w-6"></Icon3>
            {t('rent_rehearsal_room')}
          </MyLink>
          {/* <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Musikunterricht%22%7D&page=1"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Musik Unterricht
          </MyLink> */}
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/Songwriter"
            className="flex font-semibold text-black">
            <Icon2 className="h-6 w-6"></Icon2>
            Songwriter
          </MyLink>
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/Produzenten%20&%20Komponisten"
            className="flex font-semibold text-black">
            <Icon2 className="h-6 w-6"></Icon2>
            {t('producers_and_composers')}
          </MyLink>
          {/* <MyLink
            prefetch={false}
            href="/online-leistungen/S%C3%A4nger%20&%20Vokalisten"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Sänger & Vokalisten
          </MyLink> */}
          {/* <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/Studiomusiker"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Studiomusiker
          </MyLink> */}
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/Mischen%20&%20Mastern"
            className="flex font-semibold text-black">
            <Icon2 className="h-6 w-6"></Icon2>
            Online Mixing
          </MyLink>
          {/* <MyLink
            prefetch={false}
            href="/online-leistungen/Mischen%20&%20Mastern"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Online Mastering
          </MyLink> */}
          {/* <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/Sprachsynthese%20&%20KI"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Sprachsynthese & KI
          </MyLink> */}
          {/* <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Podcasts+%26+H%C3%B6rb%C3%BCcher%22%7D&page=1"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Podcast & Hörbuchücher Studios
          </MyLink> */}
          {/* <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/Erkennungsmelodien%20&%20Intros"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Erkennungsmelodien & Intros
          </MyLink>
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/Audio-%20&%20Radiobewerbung"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Audiowerbung & Radiowerbung
          </MyLink> */}
          {/* <MyLink
            prefetch={false}
            rel="nofollow"
            href="/online-leistungen/H%C3%B6rbuchproduktion"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Hörbuchproduktion
          </MyLink>
          <MyLink
            prefetch={false}
            rel="nofollow"
            href="/lokale-leistungen?filters=%7B%22services%22%3A%22Sonstige%22%7D&page=1"
            className="flex text-black font-bold">
            <PlusIcon className="h-6 w-6"></PlusIcon>
            Sonstige
          </MyLink> */}
        </div>
      </div>
    </section>
  );
};
