import { useState } from 'react';
import { MyLink } from '../MyLink';
import { useTranslation } from 'next-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function TabNav({ tabs, faqs, setFaqs }) {
  const [tab, setTab] = useState(tabs);

  const handleTabs = (event) => {
    const newtab = tab.map((t) => {
      if (t.name === event) {
        return { ...t, current: true };
      } else {
        return { ...t, current: false };
      }
    });
    setTab(newtab);

    const newfaq = faqs.map((t) => {
      if (t.name === event) {
        return { ...t, current: true };
      } else {
        return { ...t, current: false };
      }
    });
    setFaqs(newfaq);
  };

  return (
    <div>
      {/* <div className="hidden">
        <label htmlFor="tabs" className="sr-only">
          Wähle einen Tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={tabs.find((tab) => tab?.current)?.name}
          onChange={(event) => handleTabs(event)}>
          {tab.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div> */}
      <div className="block">
        <nav className="flex space-x-4 text-xs sm:text-sm lg:text-base" aria-label="Tabs">
          {tab.map((tab) => (
            <button
              key={tab.name}
              type="button"
              // href={tab.href}
              className={classNames(
                tab.current ? 'bg-indigo-100 text-indigo-600' : 'text-gray-700 hover:text-gray-500',
                'rounded-md px-3 py-2 font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => handleTabs(tab.name)}>
              {tab.translation}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

export const Faq = () => {
  const { t } = useTranslation(['homepage', 'common']);

  const tabs = [
    { name: 'Musiker', translation: t('homepage:faq_tab1_name'), href: '#', current: false },
    { name: 'Tonstudios', translation: t('homepage:faq_tab2_name'), href: '#', current: false },
    { name: 'Kunden', translation: t('homepage:faq_tab3_name'), href: '#', current: true },
    { name: 'Anbieter', translation: t('homepage:faq_tab4_name'), href: '#', current: false },
  ];

  const MusikerFaqs = [
    {
      question: t('homepage:faq_q1'),
      answer: t('homepage:faq_a1'),
    },
    {
      question: t('homepage:faq_q2'),
      answer: t('homepage:faq_a2'),
    },
    {
      question: t('homepage:faq_q3'),
      answer: t('homepage:faq_a3'),
    },
    {
      question: t('homepage:faq_q4'),
      answer: t('homepage:faq_a4'),
    },
  ];

  const TonstudioFaqs = [
    {
      question: t('homepage:faq_q5'),
      answer: t('homepage:faq_a5'),
    },
    {
      question: t('homepage:faq_q6'),
      answer: t('homepage:faq_a6'),
    },
  ];

  const KundeFaqs = [
    {
      question: t('homepage:faq_q7'),
      answer: t('homepage:faq_a7'),
    },
    {
      question: t('homepage:faq_q8'),
      answer: t('homepage:faq_a8'),
    },
    {
      question: t('homepage:faq_q9'),
      answer: t('homepage:faq_a9'),
    },
    {
      question: t('homepage:faq_q10'),
      answer: t('homepage:faq_a10'),
    },
  ];

  const AnbieterFaqs = [
    {
      question: t('homepage:faq_q11'),
      answer: t('homepage:faq_a11'),
    },
    {
      question: t('homepage:faq_q12'),
      answer: t('homepage:faq_a12'),
    },
    {
      question: t('homepage:faq_q13'),
      answer: t('homepage:faq_a13'),
    },
  ];

  const allfaqs = [
    { name: 'Musiker', arr: MusikerFaqs, current: false },
    { name: 'Tonstudios', arr: TonstudioFaqs, current: false },
    { name: 'Kunden', arr: KundeFaqs, current: true },
    { name: 'Anbieter', arr: AnbieterFaqs, current: false },
  ];

  const [faqs, setFaqs] = useState(allfaqs);

  return (
    <div className="mx-auto mb-20 px-4 sm:max-w-xl md:max-w-full md:px-24 lg:mb-10 lg:max-w-screen-xl lg:px-8 lg:py-20">
      {/* heading */}
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="mb-10 flex max-w-xl flex-col gap-3 sm:text-center md:mx-auto md:mb-10 lg:max-w-2xl">
          <div></div>
          <h2 className="mb-2 max-w-lg font-sans text-3xl font-medium leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            {t('homepage:faq_title')}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">{t('homepage:faq_desc')}</p>

          {/* button */}
          <div className="text-center max-sm:mt-2">
            <MyLink
              prefetch={false}
              href={'https://hilfe.esfaras.de/'}
              className="mt-2 rounded-lg bg-indigo-500 px-8 py-2 text-lg text-white">
              {t('common:helpcenter')}
            </MyLink>
          </div>
        </div>
      </div>
      {/* FAQ */}
      {/* <div className="max-w-screen-xl sm:mx-auto"> */}
      <div className="flex max-w-screen-xl flex-col gap-4 sm:mx-auto">
        <TabNav tabs={tabs} faqs={faqs} setFaqs={setFaqs} />
        <div className="grid grid-cols-1 gap-x-8 lg:min-h-80 lg:grid-cols-2">
          {faqs
            ?.find((faq) => faq?.current)
            ?.arr?.map((faq, index) => (
              <div key={index} className="mb-5">
                <p className="mb-4 text-xl font-medium">{faq?.question}</p>
                <p className="text-gray-700">{faq?.answer}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
