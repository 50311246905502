import Image from 'next/image';
import { FadeIn } from '../FadeIn';
import { MyLink } from '../MyLink';
import { FaEuroSign, FaPeopleRobbery } from 'react-icons/fa6';
import { GiReceiveMoney } from 'react-icons/gi';
import Heading from '../Heading';
import { IconBanner } from '../Icons';
import { useTranslation } from 'next-i18next';

const Feature = ({ icon, title, description }) => {
  return (
    <FadeIn>
      <div className="mb-8">
        {icon}
        <h3 className="text-xl font-semibold leading-6">{title}</h3>
        <p className="mt-1 max-w-[100%] text-gray-500 2xl:max-w-[80%]">{description}</p>
      </div>
    </FadeIn>
  );
};

export function HomepageBanner() {
  const { t } = useTranslation(['homepage']);

  return (
    <section className="mx-auto mb-24 mt-28 grid max-w-[90vw] grid-cols-1 gap-1 sm:gap-10 md:grid-cols-2 md:gap-10 lg:gap-28">
      <div className="mx-auto flex w-full justify-center">
        <IconBanner className="h-[400px] w-[500px] sm:h-[500px] md:h-full md:w-full lg:h-[500px] lg:w-[500px] xl:h-[600px] xl:w-[600px]" />
      </div>
      <div>
        <FadeIn>
          <Heading>{t('banner_title')}</Heading>
        </FadeIn>
        <div className="mt-10">
          <Feature
            icon={<FaEuroSign className="h-10 w-10 text-indigo-400" />}
            title={t('banner_ft1_title')}
            description={t('banner_ft1_desc')}
          />
          <Feature
            icon={<FaPeopleRobbery className="h-10 w-10 font-bold text-indigo-400" />}
            title={t('banner_ft2_title')}
            description={t('banner_ft2_desc')}
          />
          <Feature
            icon={<GiReceiveMoney className="h-10 w-10 text-indigo-400" />}
            title={t('banner_ft3_title')}
            description={t('banner_ft3_desc')}
          />
        </div>
        <FadeIn>
          <MyLink
            prefetch={false}
            className="mt-2 rounded-lg bg-indigo-500 px-8 py-2 text-lg text-white"
            href={'/fuer-anbieter'}>
            {t('banner_btn')}
          </MyLink>
        </FadeIn>
      </div>
    </section>
  );
}
